import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import ProductThumbnailListItem from './ProductThumbnailListItem';

const ProductCatalogSection = ({ products }) => (
  <CenteredSectionContainer paddingSm="0" maxWidthSm="none">
    <Grid container>
      {products.map((product, index) => (
        <Grid
          item
          xs={12}
          md={6}
          key={product.id}
        >
          <ProductThumbnailListItem
            name={product.title}
            subtitle={product.subtitle}
            gradientColorLight={product.gradientColorLight}
            gradientColorDark={product.gradientColorDark}
            image={product.thumbnail.fluid}
            slug={product.slug}
            attachedToBottomRight={index % 2 === 1}
            lastItem={index === products.length - 1}
          />
        </Grid>
      ))}
    </Grid>
  </CenteredSectionContainer>
);

ProductCatalogSection.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    slug: PropTypes.string.isRequired,
    gradientColorLight: PropTypes.string.isRequired,
    gradientColorDark: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid.isRequired,
    }),
  })).isRequired,
};

export default ProductCatalogSection;
