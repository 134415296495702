import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    height: '157px',
    padding: '60px 50px 0',
    [theme.breakpoints.down('md')]: {
      height: '125px',
      padding: '36px 30px 0',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  subtitle: {
    marginTop: '0.5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '0.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.1rem',
    },
  },
}));

export default useStyles;
