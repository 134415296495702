import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '687px',
    [theme.breakpoints.down('md')]: {
      height: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      marginBottom: (props) => (props.lastItem ? '0' : '20px'),
    },
    [theme.breakpoints.down('xs')]: {
      height: '140px',
      marginBottom: (props) => (props.lastItem ? '0' : '15px'),
    },
  },
  card: {
    position: 'absolute',
    top: (props) => (props.attachedToBottomRight ? null : '0'),
    right: (props) => (props.attachedToBottomRight ? '0' : null),
    bottom: (props) => (props.attachedToBottomRight ? '0' : null),
    left: (props) => (props.attachedToBottomRight ? null : '0'),
    width: '470px',
    height: '627px',
    background: (props) => `linear-gradient(135deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`,
    [theme.breakpoints.down('md')]: {
      width: '375px',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
}));

export default useStyles;
