import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: '100%',
    height: '470px',
    [theme.breakpoints.down('md')]: {
      height: '375px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      minWidth: '200px',
      height: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      minWidth: '140px',
      height: '140px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
