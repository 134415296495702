import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import GatsbyImage from 'gatsby-image';
import useStyles from './styles';

const ProductThumbnailListItemImage = ({ image, imageAlt }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      <GatsbyImage
        fluid={image}
        alt={imageAlt}
        className={classes.image}
      />
    </div>
  );
};

ProductThumbnailListItemImage.defaultProps = {
  imageAlt: '',
};

ProductThumbnailListItemImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
  imageAlt: PropTypes.string,
};

export default ProductThumbnailListItemImage;
