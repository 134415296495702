import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CenteredSectionContainer from '../CenteredSectionContainer';
import useStyles from './styles';

const SimpleTextSection = ({ heading, text }) => {
  const classes = useStyles();

  return (
    <CenteredSectionContainer>
      <Typography
        variant="h2"
        align="center"
        className={classes.heading}
      >
        { heading }
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className={classes.text}
      >
        { text }
      </Typography>
    </CenteredSectionContainer>
  );
};

SimpleTextSection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SimpleTextSection;
