import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const ProductThumbnailListItemText = ({ name, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.textContainer}>
      <div>
        <Typography
          variant="h3"
          color="textSecondary"
        >
          { name }
        </Typography>
        {subtitle
          ? (
            <Typography
              variant="h4"
              color="textSecondary"
              className={classes.subtitle}
            >
              { subtitle }
            </Typography>
          )
          : null}
      </div>
    </div>
  );
};

ProductThumbnailListItemText.defaultProps = {
  subtitle: '',
};

ProductThumbnailListItemText.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default ProductThumbnailListItemText;
