import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import Wires from '@components/common/Wires';
import FirstWire from '@images/wire-product-1.png';
import SecondWire from '@images/wire-product-2.png';
import ThirdWire from '@images/wire-product-3.png';
import PageHeader from '@components/common/PageHeader';
import SectionSpacer from '@components/common/SectionSpacer';
import SimpleTextSection from '@components/common/SimpleTextSection';
import ProductCatalogSection from '@components/product-list/ProductCatalogSection';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';

const ProductsPage = ({ data }) => {
  const productsPage = data.productsPage.edges[0].node;

  return (
    <Layout>
      <SEO title="Products" />
      <Wires
        wires={[
          {
            src: FirstWire,
            side: 'right',
            top: 19,
          },
          {
            src: SecondWire,
            side: 'left',
            top: 50,
          },
          {
            src: ThirdWire,
            side: 'right',
            top: 80,
          },
        ]}
      />
      <PageHeader
        pageTitle="PRODUCTS"
        image={data.headerImage.childImageSharp.fluid}
      />
      <SimpleTextSection
        heading={productsPage.introHeading}
        text={productsPage.introText.introText}
      />
      <SectionSpacer
        xl="80px"
        lg="80px"
        md="60px"
        sm="40px"
        xs="40px"
      />
      <ProductCatalogSection
        products={productsPage.productList}
      />
      <SectionSpacer />
      <FeaturedProjectsSection />
      <ContactBannerSection />
    </Layout>
  );
};

ProductsPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
};

export default ProductsPage;

export const pageQuery = graphql`
  query {
    productsPage: allContentfulProductsPage(limit: 1) {
      edges {
        node {
          id
          introHeading
          introText {
            introText
          }
          productList {
            id
            title
            subtitle
            slug
            gradientColorLight
            gradientColorDark
            thumbnail {
              title
              fluid(maxWidth: 470) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
    headerImage: file(relativePath: {eq: "page-header-products.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
