import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: '5rem 0 4rem',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 0 5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 0 3rem',
    },
  },
  text: {
    whiteSpace: 'break-spaces',
  },
}));

export default useStyles;
