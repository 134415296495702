import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import { Link } from 'gatsby';
import ProductThumbnailListItemImage from './ProductThumbnailListItemImage';
import ProductThumbnailListItemText from './ProductThumbnailListItemText';
import useStyles from './styles';

const ProductThumbnailListItem = ({
  name,
  subtitle,
  gradientColorLight,
  gradientColorDark,
  image,
  slug,
  attachedToBottomRight,
  lastItem,
}) => {
  const classes = useStyles({
    gradientColorLight,
    gradientColorDark,
    attachedToBottomRight,
    lastItem,
  });

  return (
    <div className={classes.root}>
      <Link to={`/products/${slug}`} className="no-decoration">
        <div className={classes.card}>
          <ProductThumbnailListItemText
            name={name}
            subtitle={subtitle}
          />
          <ProductThumbnailListItemImage
            image={image}
            imageAlt={name}
          />
        </div>
      </Link>
    </div>
  );
};

ProductThumbnailListItem.defaultProps = {
  subtitle: '',
  attachedToBottomRight: false,
  lastItem: false,
};

ProductThumbnailListItem.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
  slug: PropTypes.string.isRequired,
  attachedToBottomRight: PropTypes.bool,
  lastItem: PropTypes.bool,
};

export default ProductThumbnailListItem;
